import Base from "../Base";

import React from "react";
import ContractItem from "./ContractItem";
import ContractForm from "./ContractForm";
import TableSearch from "../../../Components/General/TableSearch/TableSearch";
import { Modal, Form } from "react-bootstrap";
import Error from "../../../Components/General/Alerts/error";
import AddBtn from "../../../Components/General/AddBtn";

import SearchInputObj from "../../../Components/General/SearchInputs/searchInputObj";
import Zone from "../../../Components/Zone";
import Sensor from "../../../Components/Devices/Sensor";
import LeakageSensor from "../../../Components/Devices/LeakageSensor";
import ValveNbiot from "../../../Components/Devices/ValveNbiot";
import Modem from "../../../Components/Devices/Modem";
import Counter from "../../../Components/Devices/Counter";

export default class Contracts extends Base {
  static mainUrl = "contract";
  mainUrl = "contract";

  filter = (
    <>
      <SearchInputObj
        size={12}
        label="Категория"
        source="contract-type"
        server={this.props.server}
        onSelectItem={(item) => this.setFilter("type", item.ID)}
      />
      <SearchInputObj
        size={12}
        label="Клиент"
        source="contractor"
        server={this.props.server}
        onSelectItem={(item) => this.setFilter("contractor", item.ID)}
      />
      <SearchInputObj
        size={12}
        label="Основной договор"
        source="contract"
        server={this.props.server}
        render={(item) => (
          <>
            {item.TITLE}{" "}
            {item.CONTRACTOR?.TITLE ? `(${item.CONTRACTOR?.TITLE})` : null}
          </>
        )}
        onSelectItem={(item) => this.setFilter("parent", item.ID)}
        filter={{ mode: "contract" }}
      />
      <Form.Group className={"search-input-obj col-md-12"}>
        <label>Тип</label>
        <select
          className="form-control"
          onChange={(e) => this.setFilter("mode", e.target.value)}
        >
          <option value=""></option>
          <option value="contract">Договор</option>
          <option value="subContract">Спецификация</option>
        </select>
      </Form.Group>
      <SearchInputObj
        style={{ left: "15px", right: "15px" }}
        size={12}
        label="Зона"
        source="zone"
        server={this.props.server}
        render={(item) => <Zone item={item} />}
        onSelectItem={(item) => this.setFilter("zone", item.ID)}
      />
      <SearchInputObj
        style={{ left: "15px", right: "15px" }}
        size={12}
        label="Датчик"
        source="sensor"
        server={this.props.server}
        render={(item) => <Sensor item={item} />}
        onSelectItem={(item) => this.setFilter("sensor", item.ID)}
      />
      <SearchInputObj
        style={{ left: "15px", right: "15px" }}
        size={12}
        label="ДП"
        source="leakage-sensor"
        server={this.props.server}
        render={(item) => <LeakageSensor item={item} />}
        onSelectItem={(item) => this.setFilter("leakageSensor", item.ID)}
      />
      <SearchInputObj
        style={{ left: "15px", right: "15px" }}
        size={12}
        label="ЗА"
        source="valve-nbiot"
        server={this.props.server}
        render={(item) => <ValveNbiot item={item} />}
        onSelectItem={(item) => this.setFilter("valveNbiot", item.ID)}
      />
      <SearchInputObj
        style={{ left: "15px", right: "15px" }}
        size={12}
        label="БС и МТ"
        source="modem"
        server={this.props.server}
        render={(item) => <Modem item={item} />}
        onSelectItem={(item) => this.setFilter("modem", item.ID)}
      />
      <SearchInputObj
        style={{ left: "15px", right: "15px" }}
        size={12}
        label="ПУ"
        source="counter"
        server={this.props.server}
        render={(item) => <Counter item={item} />}
        onSelectItem={(item) => this.setFilter("counter", item.ID)}
      />
    </>
  );

  render() {
    return (
      <div className="inmodule">
        <TableSearch
          filter={this.filter}
          searchParams={this.searchParams}
          setParentCallback={this.setCallback.bind(this)}
        />

        <div className="centerCount">
          Показано {this.state.cards.length} из {this.state.totalCount}
        </div>

        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Наименование</th>
              <th scope="col" style={{ width: "350px" }}>
                Клиент
              </th>
              <th scope="col" style={{ width: "250px" }}>
                Категория
              </th>
              <th scope="col" style={{ width: "350px" }}>
                Зона
              </th>
              <th scope="col" style={{ width: "150px" }}>
                Параметры
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.cards.map((item) => {
              return (
                <ContractItem
                  key={item.ID}
                  info={item}
                  onClick={this.showModal}
                />
              );
            })}
          </tbody>
        </table>

        {this.state.cards.length < this.state.totalCount
          ? this.getMoreButton()
          : null}

        <div style={{ height: "60px" }}></div>

        <AddBtn onClick={this.newClick()} />

        <Modal
          show={this.state.modalShow}
          onHide={this.hideEditModal}
          size="xl"
          fullscreen="lg-down"
        >
          <ContractForm
            id={this.state.modalItemId}
            hideCallback={this.hideEditModal}
            parentReloadCallback={this.reloadCallback}
            server={this.props.server}
            mainUrl={this.mainUrl}
          />
        </Modal>

        {this.state.errorconnect ? <Error /> : null}
      </div>
    );
  }
}
