import Base from "../Base";

import React from "react";
import ContractorItem from "./ContractorItem";
import ContractorForm from "./ContractorForm";
import TableSearch from "../../../Components/General/TableSearch/TableSearch";
import { Modal } from "react-bootstrap";
import Error from "../../../Components/General/Alerts/error";
import AddBtn from "../../../Components/General/AddBtn";

import SearchInputObj from "../../../Components/General/SearchInputs/searchInputObj";

export default class Contractors extends Base {
  static mainUrl = "contractor";
  mainUrl = "contractor";

  filter = (
    <>
      <SearchInputObj
        size={12}
        label="Категория"
        source="contractor-type"
        server={this.props.server}
        onSelectItem={(item) => this.setFilter("TYPE", item.ID)}
      />
    </>
  );

  render() {
    return (
      <div className="inmodule">
        <TableSearch
          filter={this.filter}
          searchParams={this.searchParams}
          setParentCallback={this.setCallback.bind(this)}
        />

        <div className="centerCount">
          Показано {this.state.cards.length} из {this.state.totalCount}
        </div>

        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th scope="col" style={{ width: "250px" }}>
                Наименование
              </th>
              <th scope="col" style={{ width: "250px" }}>
                Категория
              </th>
              <th scope="col">Адрес</th>
              <th scope="col" style={{ width: "150px" }}>
                Параметры
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.cards.map((item) => {
              return (
                <ContractorItem
                  key={item.ID}
                  info={item}
                  onClick={this.showModal}
                />
              );
            })}
          </tbody>
        </table>

        {this.state.cards.length < this.state.totalCount
          ? this.getMoreButton()
          : null}

        <div style={{ height: "60px" }}></div>

        <AddBtn onClick={this.newClick()} />

        <Modal
          show={this.state.modalShow}
          onHide={this.hideEditModal}
          size="xl"
          fullscreen="lg-down"
        >
          <ContractorForm
            id={this.state.modalItemId}
            hideCallback={this.hideEditModal}
            parentReloadCallback={this.reloadCallback}
            server={this.props.server}
            mainUrl={this.mainUrl}
          />
        </Modal>

        {this.state.errorconnect ? <Error /> : null}
      </div>
    );
  }
}
