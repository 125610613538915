import Base from "../Base";
import React from "react";
import BalanceItem from "./BalanceItem";
import BalanceForm from "./BalanceForm";
import TableSearch from "../../../Components/General/TableSearch/TableSearch";
import { Modal } from "react-bootstrap";
import Error from "../../../Components/General/Alerts/error";
import AddBtn from "../../../Components/General/AddBtn";

export default class Balance_groups extends Base {
  static mainUrl = "balance-group";
  mainUrl = "balance-group";

  render() {
    return (
      <div className="inmodule">
        <TableSearch
          searchParams={this.searchParams}
          setParentCallback={this.setCallback.bind(this)}
        />

        <div className="centerCount">
          Показано {this.state.cards.length} из {this.state.totalCount}
        </div>

        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Название</th>
              <th scope="col" style={{ width: "150px" }}>
                Параметры
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.cards.map((item) => {
              return (
                <BalanceItem
                  key={item.ID}
                  info={item}
                  onClick={this.showModal}
                />
              );
            })}
          </tbody>
        </table>

        {this.state.cards.length < this.state.totalCount
          ? this.getMoreButton()
          : null}

        <div style={{ height: "60px" }}></div>

        <AddBtn onClick={this.newClick()} />

        <Modal
          show={this.state.modalShow}
          onHide={this.hideEditModal}
          size="xl"
          fullscreen="lg-down"
        >
          <BalanceForm
            id={this.state.modalItemId}
            hideCallback={this.hideEditModal}
            parentReloadCallback={this.reloadCallback}
            server={this.props.server}
            mainUrl={this.mainUrl}
          />
        </Modal>

        {this.state.errorconnect ? <Error /> : null}
      </div>
    );
  }
}
