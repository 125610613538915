import Base from "../Base";
import React from "react";
import ModemForm from "./ModemForm";
import ModemItem from "./ModemItem";
import TableSearch from "../../../Components/General/TableSearch/TableSearch";
import { Modal } from "react-bootstrap";
import Error from "../../../Components/General/Alerts/error";
import AddBtn from "../../../Components/General/AddBtn";

import SearchInputObj from "../../../Components/General/SearchInputs/searchInputObj";
import Zone from "../../../Components/Zone";

import { permissions, canCreate } from "../../ServerPermissions/Permissions";

export default class Modems extends Base {
  static mainUrl = "modem";
  mainUrl = "modem";

  filter = (
    <SearchInputObj
      size={12}
      label="Зона"
      source="zone"
      server={this.props.server}
      render={(item) => <Zone item={item} />}
      onSelectItem={(item) => this.setFilter("zone", item.ID)}
    />
  );

  render() {
    return (
      <div className="inmodule">
        <TableSearch
          filter={this.filter}
          searchParams={this.searchParams}
          setParentCallback={this.setCallback.bind(this)}
        />

        <div className="centerCount">
          Показано {this.state.cards.length} из {this.state.totalCount}
        </div>

        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th scope="col" style={{ width: "50px" }}>
                Тип
              </th>
              <th scope="col" style={{ width: "200px" }}>
                Серийный номер
              </th>
              <th scope="col" style={{ width: "200px" }}>
                IMEI / MAC
              </th>
              <th scope="col" style={{ width: "200px" }}>
                ICCID
              </th>
              <th scope="col" style={{ width: "300px" }}>
                Наименование
              </th>
              <th scope="col">Зона</th>
              <th scope="col" style={{ width: "150px" }}>
                Параметры
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.cards.map((item) => {
              return (
                <ModemItem key={item.ID} info={item} onClick={this.showModal} />
              );
            })}
          </tbody>
        </table>

        {this.state.cards.length < this.state.totalCount
          ? this.getMoreButton()
          : null}

        <div style={{ height: "60px" }}></div>

        {canCreate(permissions.modem, this.props.serverPermissions) ? (
          <AddBtn onClick={this.newClick()} />
        ) : null}

        <Modal
          show={this.state.modalShow}
          onHide={this.hideEditModal}
          size="xl"
          fullscreen="lg-down"
        >
          <ModemForm
            id={this.state.modalItemId}
            hideCallback={this.hideEditModal}
            parentReloadCallback={this.reloadCallback}
            server={this.props?.server}
            setConfirmModal={this.props?.setConfirmModal}
            mainUrl={this.mainUrl}
            serverPermissions={this.props.serverPermissions}
          />
        </Modal>

        {this.state.errorconnect ? <Error /> : null}
      </div>
    );
  }
}
