import Base from "../Base";
import React from "react";
import WeatherForm from "./WeatherForm";
import WeatherItem from "./WeatherItem";
import TableSearch from "../../../Components/General/TableSearch/TableSearch";
import { Modal } from "react-bootstrap";
import Error from "../../../Components/General/Alerts/error";
import AddBtn from "../../../Components/General/AddBtn";

export default class WeatherSource extends Base {
  static mainUrl = "weather-source";
  mainUrl = "weather-source";

  render() {
    return (
      <div className="inmodule">
        <TableSearch
          searchParams={this.searchParams}
          setParentCallback={this.setCallback.bind(this)}
        />

        <div className="centerCount">
          Показано {this.state.cards.length} из {this.state.totalCount}
        </div>

        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Название</th>
              <th scope="col" style={{ width: "550px" }}>
                Идентификатор населенного пункта в OpenWeatherMap
              </th>
              <th scope="col" style={{ width: "200px" }}>
                Интервал опроса (час)
              </th>
              <th scope="col" style={{ width: "150px" }}>
                Параметры
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.cards.map((item) => {
              return (
                <WeatherItem
                  key={item.CITY_ID}
                  info={item}
                  onClick={this.showModal}
                />
              );
            })}
          </tbody>
        </table>

        {this.state.cards.length < this.state.totalCount
          ? this.getMoreButton()
          : null}

        <div style={{ height: "60px" }}></div>

        <AddBtn onClick={this.newClick()} />

        <Modal
          show={this.state.modalShow}
          onHide={this.hideEditModal}
          size="lg"
          fullscreen="lg-down"
        >
          <WeatherForm
            id={this.state.modalItemId}
            hideCallback={this.hideEditModal}
            parentReloadCallback={this.reloadCallback}
            server={this.props.server}
            mainUrl={this.mainUrl}
          />
        </Modal>

        {this.state.errorconnect ? <Error /> : null}
      </div>
    );
  }
}
