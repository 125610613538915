import React from "react";
import BaseItem from "../BaseItem";
import "../../../transfer-tech.css";

export default class ModemItem extends BaseItem {
  render() {
    const icon = this.props.info.TECH_ICON ? (
      <i
        className={`transfer-tech details-tech ${this.props.info.TECH_ICON}`}
      ></i>
    ) : null;

    return (
      <tr
        onClick={this.onClick.bind(this, this.props.info.ID)}
        style={
          this.props.info.IS_NOT_ACTIVE
            ? { color: "gray", cursor: "pointer" }
            : { cursor: "pointer" }
        }
      >
        <td>{icon}</td>
        <td title={this.props.info.ID}>
          <small>{this.props.info.SERNUMBER}</small>
        </td>

        <td>
          <div><small>{this.props.info.IMEI}</small></div>
          <div><small>{this.props.info.MAC}</small></div>
        </td>

        <td>
          <small>{this.props.info.ICCID}</small>
        </td>

        <th>{this.props.info.TITLE}</th>

        <td>{this.props.info.SERVICE_AREA_TITLE}</td>

        <td style={{ textAlign: "left" }}>
          <i
            className="fas fa-fw fa-clipboard"
            title="Скопировать GUID"
            onClick={this.copyCB.bind(this, this.props.info.ID)}
          >
            {" "}
          </i>
          {this.props.info.IS_NOT_ACTIVE ? (
            <i className="fas fa-fw fa-power-off" title="Не активный">
              {" "}
            </i>
          ) : (
            <i className="fas fa-fw"> </i>
          )}
          {this.props.info.COMMENT ? (
            <i
              className="fas fa-fw fa-comment-dots"
              title={this.props.info.COMMENT}
            >
              {" "}
            </i>
          ) : (
            <i className="fas fa-fw"> </i>
          )}
        </td>
      </tr>
    );
  }
}
